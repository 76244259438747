<template>
  <div
    class="filter-badges"
    :class="{'not-styles': notStyles}"
    v-if="getSize"
  >
    {{ getSize }}
  </div>
</template>

<script>
export default {
  name: "BankFilterBadges",
  props: {
    filterData: Object,
    allData: Object,
    notStyles: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getSize() {
      let count = 0;

      for(let [key, item] of Object.entries(this.filterData)) {
        const allDataEl = this.allData[`all_${key}`];
        if (allDataEl) {
          if (typeof item === "string") {
            count += (item === '*' || item === '') ? 0 : 1
          } else {
            count += item.length === allDataEl.length || !item.length ? 0 : 1
          }
        }
      }

      this.$emit('getSize', count)
      return count
    }
  }
}
</script>

<style scoped lang="scss">
.filter-badges:not(.not-styles) {
  width: 21px;
  height: 21px;
  background: #E22D21;
  border: 1px solid #FFFFFF;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>
