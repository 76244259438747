<template>
  <div class="feedbacks-page card card-padding h-100" :class="{ 'is-show-filter': showFilter }">
    <PageHeader
      @showFilter="showFilter = true"
      :disabled="filterLoading"
      :showFilter="showFilter"
      :search-input-text="$t('Search ideas...')"
      @handleInput="handleSearch"
    >
      <template #filter-badges>
        <BankFilterBadges
          :filterData="getFilterForm"
          :allData="filterAllData"
          :not-styles="true"
          @getSize="filterCount = $event"
        />
      </template>
    </PageHeader>

    <div class="feedbacks-wrapper">
      <div
        v-if="showFilter"
        class="filter feedbacks-wrapper__filter">
        <p
          @click="showFilter = false"
          class="closeFilter">
          <img width="20" src="/media/buying/icons/close.svg" alt="">
        </p>
        <BankIdeaFilter
          :initialFilters="filterData.filter"
          :countries="bankCountries"
          :productAreas="bankProductAreas"
          :companies="companies"
          :filterCount="filterCount"
          @handle-close-filter="showFilter = false"
          @apply-filters="applyIdeaFilters"
        />
      </div>
      <div class="feedbacks-wrapper__list">
        <div
          v-if="!loading && ideas.length"
          v-for="idea in ideas"
          class="mt-8"
        >
          <BankIdeaItem
            :ideaData="idea"
            :showFilter="showFilter"
            @handle-toggle-status="toggleIdeaStatus(idea)"
          />
        </div>

        <div
          v-else-if="!loading && !ideas.length"
          class="h-100 d-flex align-items-center justify-content-center"
        >
          <CustomLoader height="400px" v-if="filterLoading" />
          <RequestIdeaEmptyState
            v-else
            :title="$t('No Idea Found')"
            :text="filterCount
              ? $t('There are no open ideas to be found. This might have happened due to the filters applied.')
              : $t('There are no open ideas yet. Please check back later.')
            "
            :icon-path="filterCount
              ? '/media/buying/icons/no-idea-found-filter.png'
              : '/media/buying/icons/no-idea-found.svg'
            "
          />
        </div>
        <CustomLoader height="400px" v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import {setCurrentPageBreadcrumbs, setCurrentPageCounter} from "@/core/helpers/breadcrumb";
import PageHeader from "@/buying-teams/shared-components/feedbacks/PageHeader";
import store from "@/store";
import BankIdeaFilter from "@/buying-teams/pages/bank/ideas/BankIdeaFilter";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import BankIdeaItem from "@/buying-teams/pages/bank/ideas/BankIdeaItem";
import {BankIdeaFilterModel} from "@/store/models/filter/bank/BankIdeaFilterModel";
import { RequestStatusEnum } from "@/store/enums/request/RequestStatusEnum";
import { RequestStatusModel } from "@/store/models/request/RequestStatusModel";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import BankFilterBadges from "@/buying-teams/shared-components/filter/BankFilterBadges";
import RequestIdeaEmptyState from "@/buying-teams/shared-components/utils/RequestIdeaEmptyState";

export default {
  name: "BankIdeas",
  components: {
    RequestIdeaEmptyState,
    BankFilterBadges,
    BankIdeaItem,
    CustomLoader,
    BankIdeaFilter,
    PageHeader
  },
  data() {
    return {
      filterLoading: true,
      showFilter: false,
      searchTimeoutId: null,
      filterCount: 0,
      filterData: {
        type: "idea",
        search: "",
        show_closed_items: false,
        filter: {
          product_areas: [],
          time_period: "*",
          countries: [],
          customers: [],
          show_closed_items: false
        }
      }
    }
  },
  async mounted() {
    setCurrentPageBreadcrumbs({
      title: this.$t('Ideas'),
      subTitle: this.$t('Ideas for your Customers (improvements, developments, other)')
    })
      store.commit('SAVE_BANK_IDEA_LOADING', true);
      await store.dispatch('initFiltersData', { type: BankFiltersTypeEnum.IDEA });

    if (!this.bankIdeaFilter) {
      this.filterData.filter.product_areas = this.bankProductAreas;
      this.filterData.filter.countries = this.bankCountries;
      this.filterData.filter.customers = this.companies.map(c => c.id);
    } else {
      this.setFilters(this.bankIdeaFilter)
    }
    await this.getIdeas(this.filterData);

    this.filterLoading = false;
  },
  computed: {
    ideas() {
      return store.getters.getBankIdeasData
    },
    loading() {
      return store.getters.getBankIdeasLoading
    },
    companies() {
      if (!store.getters.getBankFiltersState.ideas.customers) {
        return []
      }
      return store.getters.getBankFiltersState.ideas.customers.map(c => {
        return {
          id: c.id,
          name: c.name,
          icon_path: c.icon_path
        }
      })
    },
    bankCountries() {
      return store.getters.getBankFiltersState.ideas.countries;
    },
    bankProductAreas() {
      return store.getters.getBankFiltersState.ideas.product_areas;
    },
    bankIdeaFilter() {
      return store.getters.bankIdeaFilter;
    },
    timePeriods() {
      return store.getters.timePeriods;
    },
    getFilterForm() {
      return {
        product_areas: this.filterData.filter.product_areas,
        time_period: this.filterData.filter.time_period,
        countries: this.filterData.filter.countries,
        customers: this.filterData.filter.customers,
      }
    },
    filterAllData() {
      return {
        all_product_areas: this.bankProductAreas,
        all_time_period: this.timePeriods,
        all_countries: this.bankCountries,
        all_customers: this.companies,
      }
    }
  },
  methods: {
    setFilters(filters) {
      this.filterData.filter = filters;
      this.filterData.show_closed_items = filters.show_closed_items
    },
    async applyIdeaFilters(filters) {
        setCurrentPageCounter({counter: null});
        store.commit('SAVE_BANK_IDEA_LOADING', true);
        this.setFilters(new BankIdeaFilterModel(filters));
      await this.getIdeas(this.filterData);
    },
    async getIdeas(filter) {
      await store.dispatch('getBankIdeas', filter)
      setCurrentPageCounter({counter: this.ideas.length})
    },
    async toggleIdeaStatus(idea) {
      let status = idea.status.code === RequestStatusEnum.OPEN ? RequestStatusEnum.CLOSED : RequestStatusEnum.OPEN
      const params = {
        id: idea.id,
        status: status
      };
      await store.dispatch('toggleIdeaStatus', params)
        .then(res => {
          if (res) {
            idea.status = new RequestStatusModel(status);
          }
        })
    },
    async handleSearch(event) {
      let search = event.target.value

      if (search !== this.filterData.search && this.searchTimeoutId) clearTimeout(this.searchTimeoutId)
      this.filterData.search = search;

      this.searchTimeoutId = setTimeout(async () => {
        await this.getIdeas(this.filterData);
      }, 500);
    }
  }
};
</script>
<style lang="scss" scoped>
.filter-badges {
  width: 21px;
  height: 21px;
  background: #E22D21;
  border: 1px solid #FFFFFF;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  left: -18px;
}
</style>
