<template>
  <div class="card card-hover-shadow idea-request-card" :class="{singleRequest: singleRequest, 'show-filter': showFilter}">
    <div class="top-section">
      <div class="d-flex align-items-center top-section__content">
        <StatusComponent
          v-if="singleRequest"
          :text="ideaData.status.statusName"
          :type="ideaData.status.statusClass"
        />
        <div class="top-section__name">
          <div class="top-section__name--img">
            <img :src="ideaData.business_icon_path" alt="">
          </div>
          <span>{{ ideaData.business_name }}</span>
        </div>
        <div class="top-section__user">
          <div class="top-section__user--img">
            <img :src="ideaData.creator.icon_path" alt="">
          </div>
          <span>{{ ideaData.creator.first_name }} {{ ideaData.creator.last_name }}</span>
        </div>
        <div class="top-section__user">
          <div class="top-section__user--img">
            <img src="/media/buying/project-manager.svg" alt="">
          </div>
          <span>{{ ideaData.creator.job_title }}</span>
        </div>
      </div>
    </div>
    <hr class="mt-0" v-if="singleRequest">
    <div class="d-lg-flex justify-content-between align-items-center mb-4" v-if="!singleRequest">
      <StatusComponent
        :text="ideaData.status.statusName"
        :type="ideaData.status.statusClass"
      />
      <div class="comment-section">
        <img width="18" src="/media/buying/icons/comment.svg" alt="">
        <span class="text-gray mx-2 fw-bold">{{ideaData.comments_count}}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div class="d-flex justify-content-center card-title-blog">
        <router-link :to="`/bank/feedback/idea/${ideaData.id}`">
          <h4 class="title mb-0">
            {{ideaData.title}}
          </h4>
        </router-link>
      </div>
    </div>
    <div class="d-flex w-100">
      <div class="msg-block px-0 w-100">
        <p>{{ideaData.description}}</p>
      </div>
    </div>
    <hr v-if="!showFilter">
    <div
      v-if="!showFilter"
      class="d-xxl-flex justify-content-between align-items-center">
      <div class="d-flex flex-wrap align-items-center">
        <p class="my-3 pe-5 me-5 border-end">{{ $t("ID") }} <b>{{ideaData.id}}</b></p>
        <p class="text-gray me-3 my-3">{{$t("Product Area")}}</p>
        <div class="d-flex flex-wrap w-lg-auto">
          <p v-for="(area, i) in ideaData.product_areas" class="my-3">
            <span v-if="i < 3" class="text-with-bg me-3">{{ area }}</span>
          </p>
          <p v-if="ideaData.product_areas.length > 3" class="my-3"><span class="text-with-bg me-3">+ {{ ideaData.product_areas.length - 3 }}</span>
          </p>
        </div>
      </div>
      <template v-if="currentUser.can(BankUserPermissionTypeEnum.OPEN_CLOSE_REQUEST_IDEA)">
        <button
          v-if="ideaData.status.code === RequestStatusEnum.OPEN"
          :disabled="buttonLoading"
          :class="{ buttonLoading: buttonLoading }"
          class="open-close-btn close mb-0"
          @click="toggleIdeaStatus(ideaData.id)"
        >
          <template v-if="buttonLoading">
            <span class="spinner-border spinner-border-sm align-middle me-4"></span>
            {{ $t("Closing") }}
          </template>
          <template v-else>
            <img src="/media/buying/icons/close-red.svg" class="me-3" alt="">
            {{ $t("Close Idea") }}
          </template>
        </button>
        <button
          v-else-if="ideaData.status.code === RequestStatusEnum.CLOSED"
          class="open-close-btn open mb-0"
          :disabled="buttonLoading"
          :class="{ buttonLoading: buttonLoading }"
          @click="toggleIdeaStatus(ideaData.id)"
        >
          <template v-if="buttonLoading">
            <span class="spinner-border spinner-border-sm align-middle me-4"></span>
            {{ $t("Opening") }}
          </template>
          <template v-else>
            {{ $t("Open Idea") }}
          </template>
        </button>
      </template>
    </div>
  </div>
</template>
<script>

  import { RequestStatusEnum } from "@/store/enums/request/RequestStatusEnum";
  import StatusComponent from "@/buying-teams/shared-components/utils/StatusComponent";
  import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";
  import store from "@/store";

  export default {
    name: "BankIdeaItem",
    components: {StatusComponent},
    props: [
      "showFilter",
      "ideaData",
      "singleRequest"
    ],

    emits: ['handle-toggle-status'],

    data() {
      return {
        BankUserPermissionTypeEnum,
        RequestStatusEnum
      };
    },

    computed: {
      buttonLoading() {
        return this.$store.getters.getToggleIdeaStatusLoading === this.ideaData.id
      },
      currentUser() {
        return store.getters.currentUser;
      }
    },

    methods: {
      toggleIdeaStatus(ideaId) {
        this.$emit("handle-toggle-status", ideaId);
      }
    }
  };
</script>
<style lang="scss" scoped>
.idea-request-card {
  &.card {
    position: relative;

    .comment-section {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 6px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .top-section {
      background: #F7F7F7;
      border-radius: 12px 12px 0 0;
      margin: -18px -23px 0 -23px;
      padding: 10px 23px 16px 23px;
      display: flex;
      align-items: center;
      margin-bottom: 11px;

      &__content {
        width: 100%;
        overflow: auto;
        white-space: pre;
        padding-bottom: 4px;
        margin-bottom: -4px;
      }

      > p.text-gray {
        white-space: pre;
        margin-left: 15px;
      }

      &__name {
        display: flex;
        align-items: center;
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        width: max-content;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-left: 8px;
        }

        &--img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      &__user {
        display: flex;
        align-items: center;
        margin-right: 20px;

        &--img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 13px;
          color: #8B8B8B;
          opacity: 0.87;
        }
      }
    }

    &.show-filter {
      .comment-section {
        position: absolute;
        right: 16px;
        bottom: 16px;
      }

      .top-section {
        background: #fff;
        margin-bottom: 0;
        padding-bottom: 10px;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;

        .text-gray {
          font-size: 12px;
        }

        &__user {
          &--img {
            width: 20px;
            height: 20px;
          }

          span {
            font-size: 12px;
            line-height: 13px;
          }
        }

        &__name {
          border: none;
          margin-right: 0;

          span {
            font-size: 11.013px;
            line-height: 13px;
            margin-left: 4px;
          }

          &--img {
            width: 20px;
            height: 20px;
          }
        }

        .status-label {
          font-size: 11px;
          line-height: 13px;
        }
      }
    }

    &.singleRequest {
      .top-section {
        background: #fff;
        margin-bottom: 0;
        padding-bottom: 10px;

        &__name {
          border: none;
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
  }

  .card-hover-shadow {
    padding-right: 30px;
  }

  .likedText {
    line-height: 14px;

    &.active {
      span {
        color: #435BF4;
      }

      svg {
        path {
          fill: #435BF4;
        }
      }
    }
  }

  .card-title-blog {
    .title {
      font-weight: 600;
      font-size: 16px;
      color: #2B2B2B;
    }
  }

  .msg-block {
    p {
      font-size: 14px;
      line-height: 22px;
      color: #595959;
    }
  }

  .card-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #666666;
    margin-bottom: 0;
  }

  .user-block {
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 158.02%;
      color: #969696;
    }

    span {
      font-weight: 400;
      font-size: 11px;
      line-height: 158.02%;
      color: #969696;
    }
  }

  .edit {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .idea-icon_path {
    border-radius: 50%;
    object-fit: cover;
  }

  @media (max-width: 600px) {
    &.card {
      padding: 18px 15px;

      .top-section {
        padding: 10px 15px 10px 15px;
        margin: -18px -15px 15px -15px;
        &__name {
          padding-right: 8px;
          margin-right: 8px;

          &--img {
            display: flex;
            width: 15px;
            height: 15px;
          }
        }

        &__user {
          margin-right: 0;

          &:not(:last-child) {
            margin-right: 8px;
            padding-right: 8px;
            border-right: 1px solid rgba(0, 0, 0, 0.05);
          }

          span {
            margin-left: 6px;
          }

          &--img {
            display: flex;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
</style>
