<template>
  <div class="demo-filterForm">
    <el-form :model="filterForm" ref="requestFilter">
      <el-form-item class="me-6">
        <label class="d-block" for="">{{ $t('Time Period') }}</label>
        <el-select
          v-model="filterForm.time_period"
          :placeholder="$t('Time Period')"
        >
          <el-option
            v-for="(item,index) in timePeriods"
            :key="index"
            :label="item.title"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="me-6">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogCompaniesVisible = true" class="select-popup"></div>

          <label class="d-block">{{ $t("Customers") }}</label>
          <el-select multiple disabled v-model="filterForm.customers" collapse-tags :placeholder="$t('Select Companies')">
            <el-option
              v-for="(item,index) in companies"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="me-6">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogProductAreaVisible = true" class="select-popup"></div>

          <label class="d-block mt-5">{{ $t("Product Area") }}</label>
          <el-select multiple disabled v-model="filterForm.product_areas" collapse-tags :placeholder="$t('Select Product')">
            <el-option
              v-for="(item,index) in productAreas"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="me-6">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogCountriesVisible = true" class="select-popup"></div>

          <label class="d-block mt-5" for="">{{ $t("Country of the Bank") }}</label>
          <el-select multiple disabled v-model="filterForm.countries" collapse-tags :placeholder="$t('Select Country')">
            <el-option
              v-for="(item,index) in countries"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="switch mt-14 me-6" :label=" $t('Show closed ideas') " prop="delivery">
        <el-switch v-model="filterForm.show_closed_items"></el-switch>
      </el-form-item>

    </el-form>

    <div class="filters-counts mt-0 mb-6 me-6" v-show="filterCount">
      <span>
        <div class="filter-badges not-styles">{{ filterCount }}</div>
        {{ $t('Filters Selected') }}
      </span>
      <button @click="clearAll">{{ $t('Clear All') }}</button>
    </div>

    <div class="me-6">
      <button class="main-btn-outline filter-btn" @click="applyFilters">{{ $t("Apply Filters") }}</button>
    </div>
  </div>

  <div v-if="dialogCompaniesVisible">
    <SelectModal
      :popupVisible="dialogCompaniesVisible"
      :dataForSelect="companies"
      :dataForUpdate="filterForm.customers"
      :multiple="true"
      :title="$t('Select Companies')"
      :subtitle="$t('Select relevant companies')"
      :placeholder="$t('Search Companies')"
      @handle-close="dialogCompaniesVisible = false"
      @handle-confirm="handleCompaniesSelect"
    />
  </div>

  <SelectModal
    v-if="dialogProductAreaVisible"
    :title="$t('Select Product Areas')"
    :subtitle="$t('Select Product Areas you want filter on')"
    :placeholder="$t('Search Product Areas')"
    :popupVisible="dialogProductAreaVisible"
    :dataForSelect="productAreas"
    :dataForUpdate="filterForm.product_areas"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogProductAreaVisible = false"
    @handle-confirm="handleConfirmProductAreas"
  />

  <SelectModal
    v-if="dialogCountriesVisible"
    :title="$t('Select Country')"
    :subtitle="$t('Select Country you want to filter on')"
    :placeholder="$t('Search Country')"
    :popupVisible="dialogCountriesVisible"
    :dataForSelect="countries"
    :dataForUpdate="filterForm.countries"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogCountriesVisible = false"
    @handle-confirm="handleConfirmCountries"
  />
</template>
<script>
  import SelectModal from "@/buying-teams/shared-components/modals/SelectModal";
  import store from "@/store";

  export default {
    name: "BankIdeaFilter",

    components: {
      SelectModal
    },

    props: {
      countries: Array,
      productAreas: Array,
      companies: Array,
      initialFilters: Object,
      filterCount: Number
    },

    emits: [
      "handle-close-filter",
      "apply-filters",
      "getSize"
    ],

    created() {
      this.fillFilter(this.initialFilters);
    },

    computed: {
      timePeriods() {
        return store.getters.timePeriods;
      },
      getFilterForm() {
        return {
          product_areas: this.filterForm.product_areas,
          time_period: this.filterForm.time_period,
          countries: this.filterForm.countries,
          customers: this.filterForm.customers,
        }
      },
      filterAllData() {
        return {
          all_product_areas: this.productAreas,
          all_time_period: this.timePeriods,
          all_countries: this.countries,
          all_customers: this.companies,
        }
      }
    },

    data() {
      return {
        dialogCompaniesVisible: false,
        dialogCreatorsVisible: false,
        dialogProductAreaVisible: false,
        dialogCountriesVisible: false,

        filterForm: {
          product_areas: [],
          time_period: "*",
          countries: [],
          customers: [],
          show_closed_items: false
        }
      };
    },

    methods: {
      handleCompaniesSelect(event) {
        this.dialogFormVisible = false;
        this.filterForm.customers = [ ...event ];
      },
      handleCreatorsSelect(event) {
        this.dialogFormVisible = false;
      },
      applyFilters() {
        this.$emit("apply-filters", this.filterForm);
      },
      closeFilter() {
        this.$emit("handle-close-filter");
      },
      fillFilter(filterData) {
        this.filterForm.customers = filterData.customers;
        this.filterForm.countries = filterData.countries;
        this.filterForm.product_areas = filterData.product_areas;
        this.filterForm.time_period = filterData.time_period;
        this.filterForm.show_closed_items = filterData.show_closed_items;
      },
      handleConfirmProductAreas(event) {
        this.filterForm.product_areas = event;
        this.dialogProductAreaVisible = false;
      },
      handleConfirmCountries(event) {
        this.filterForm.countries = event;
        this.dialogCountriesVisible = false;
      },
      clearAll() {
        this.filterForm.customers = this.companies.map(c => c.id);
        this.filterForm.countries = this.countries;
        this.filterForm.product_areas = this.productAreas;
        this.filterForm.time_period = '*';
        this.applyFilters();
      },
    }
  };
</script>
<style lang="scss">
  .demo-filterForm {
    .el-date-editor {
      height: 45px;

      .el-range-separator {
        margin: 0 10px;
        text-transform: lowercase;
        font-weight: 500;
        font-size: 17px;
        text-align: center;
        color: #4F4F4F;
      }
    }
  }
</style>
